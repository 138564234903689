import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4646a77f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "business" }
const _hoisted_2 = { class: "userNameforreload" }
const _hoisted_3 = { style: {"font-weight":"900"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkOutlined = _resolveComponent("LinkOutlined")!
  const _component_Item = _resolveComponent("Item")!
  const _component_SubMenu2 = _resolveComponent("SubMenu2")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Sider = _resolveComponent("Sider")!
  const _component_Content = _resolveComponent("Content")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_ctx.showSider)
    ? (_openBlock(), _createBlock(_component_Layout, {
        key: 0,
        class: "Sider_layoutComponents",
        id: "Sider"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Sider, {
            style: { background: '#fff' },
            class: "sider"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(this.$store.state.userInfo.tenant), 1)
              ]),
              _createVNode(_component_Menu, {
                style: _normalizeStyle({ width: '200px', 'min-height': _ctx.menuHeight }),
                openKeys: _ctx.openKeys,
                "onUpdate:openKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openKeys) = $event)),
                selectedKeys: _ctx.selectedKeys,
                "onUpdate:selectedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedKeys) = $event)),
                mode: "inline",
                class: "myMenu"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: item.idx || item.path
                    }, [
                      (!item.children)
                        ? (_openBlock(), _createBlock(_component_Item, {
                            key: item.idx || item.path,
                            onClick: ($event: any) => (_ctx.handleClick(item)),
                            disabled: item.disable,
                            class: "itemBox"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_3, [
                                _createTextVNode(_toDisplayString(item.meta && item.meta.title ? item.meta.title : item.name), 1),
                                (item.isLink)
                                  ? (_openBlock(), _createBlock(_component_LinkOutlined, { key: 0 }))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["onClick", "disabled"]))
                        : (_openBlock(), _createBlock(_component_SubMenu2, {
                            key: 1,
                            item: item
                          }, null, 8, ["item"]))
                    ], 64))
                  }), 128))
                ]),
                _: 1
              }, 8, ["style", "openKeys", "selectedKeys"])
            ]),
            _: 1
          }),
          (_ctx.breadcrumbType)
            ? (_openBlock(), _createBlock(_component_Content, {
                key: 0,
                breadcrumb: [_ctx.$route.matched],
                type: _ctx.breadcrumbType
              }, null, 8, ["breadcrumb", "type"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}