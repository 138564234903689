
import { defineComponent } from "vue";
import { Layout, Breadcrumb, Spin } from "ant-design-vue";
import { HomeOutlined, RightOutlined } from "@ant-design/icons-vue";

const { Content } = Layout;
const { Item } = Breadcrumb;

export default defineComponent({
  components: {
    Content,
    Breadcrumb,
    Layout,
    Item,
    HomeOutlined,
    RightOutlined,
    Spin,
  },
  props: {
    breadcrumb: {
      type: Array,
      default: [],
    },
    type: {
      type: String, //可选项 1 . apiMenu 左侧菜单通过接口获取 面包屑根据接口渲染 2.localMenu本地项目的menu
      default: "",
    },
  },
  data() {
    return {
      notGet: false, //针对apiMenu模式 接口获取的菜单中未获取到当前页面
      minHeight: "90px",
      apiBreadcrumb: [],
    };
  },
  methods: {
    tokanban() {
      window.open((this as any).$store.state.userInfo.uri_kanban, "_self");
    },
    getAppUrl() {
      if ((this as any).$store.state.sideBarApp.length) {
        return (this as any).$store.state.sideBarApp.filter((el) => {
          return el.name == "MgtMyApp";
        })[0].path;
      } else {
        return "#";
      }
    },
    getApiItem() {
      let item = { idx: "notGet" };
      const stack: any = [];
      stack.push(...(this as any).$store.state.apiMenu.menu);
      while (stack.length) {
        const element = stack.pop();
        if (element.path === this.$route.path) {
          item = element;
          break;
        }
        if (element.children && element.children.length) {
          stack.push(...element.children);
        }
      }
      return item;
    },
    getApiBreadcrumb() {
      this.notGet = false;
      if (this.type === "apiMenu") {
        const nowIdx = (this as any).getApiItem().idx;
        if (nowIdx === "notGet") {
          //在muen接口中未找到当前页面的路由则按照本地路由渲染面包屑
          this.notGet = true;
          return;
        }
        let reslist: any = [];
        const stack: any = [];
        stack.push(...(this as any).$store.state.apiMenu.menu);
        while (stack.length) {
          const element = stack.pop();
          if (nowIdx.indexOf(element.idx) > -1) {
            reslist = [...reslist, element];
          }
          if (nowIdx === element.idx) {
            break;
          }
          if (element.children && element.children.length) {
            stack.push(...element.children);
          }
        }
        this.apiBreadcrumb = reslist;
      }
    },
  },
  mounted() {
    //当菜单栏从接口获取时 type === "apiMenu" 面包屑也进行对应修改.  否则使用之前逻辑
    this.getApiBreadcrumb();
    this.minHeight = document.body.clientHeight - 120 + "px";
  },
  watch: {
    $route() {
      this.getApiBreadcrumb();
    },
  },
});
