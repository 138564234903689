import request from '../utils/request.js';
//获取其他app的路径和名字
export function getApp() {
  return request().get(`/api/iam/v1/a/menu/app`)
}
//获取用户信息
export function userInfo() {
  return request().get("/api/iam/v1/a/odic/user_info");
}
// 获取右上方未读消息
export function getMsgByUrl(url, headers) {
  return request().get(url, { headers: headers });
}
//获取权限列表
export function authc(params) {
  return request().post(`/api/iam/v1/a/odic/authc`, {
    ...params,
  })
}
//获取菜单
export function getMenu() {
  return request().get(`/api/iam/v1/a/app/menu`)
}