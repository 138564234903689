import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Layout from "../views/Layout.vue";
import store from "../store";
import { afterLogout } from "@/utils/requestFunction.js";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "/",
    redirect: "/enterprise",
    component: Layout,
    children: [],
  },
  {
    path: "/index",
    redirect: "/enterprise",
    name: "关联企业",
    component: Layout,
    meta: { hidden: false, title: "关联企业" },
    children: [
      {
        path: "/enterprise",
        name: "关联企业",
        component: () => import("@/views/relation/List.vue"),
        meta: { hidden: false, title: "关联企业" },
      },
      // {
      //   path: "/customer",
      //   name: "客户",
      //   component: () => import("@/views/relation/List.vue"),
      //   meta: { hidden: false, title: "客户" },
      // },
      // {
      //   path: "/provider",
      //   name: "供应商",
      //   component: () => import("@/views/relation/List.vue"),
      //   meta: { hidden: false, title: "供应商" },
      // },
      // {
      //   path: "/executor",
      //   name: "执行公司",
      //   component: () => import("@/views/relation/List.vue"),
      //   meta: { hidden: false, title: "执行公司" },
      // },
      // {
      //   path: "/reseller",
      //   name: "经销商",
      //   component: () => import("@/views/relation/List.vue"),
      //   meta: { hidden: false, title: "经销商" },
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.state.isLogin === false) {
    afterLogout().then((res) => {
      if (res.data.success) {
        store.commit("changeisLogin", true);
        next();
      }
    });
  } else {
    next();
  }
});
export default router;
