const configModule = {
  namespaced: true, //开启命名空间
  state: () => ({
    getRelationType() {
      switch (location.pathname) {
        case "/customer":
          return "customer";
        case "/provider":
          return "provider";
        case "/executor":
          return "executor";
        case "/reseller":
          return "reseller";
        case "/enterprise":
          return "enterprise";
      }
    },
    getRelationName(data) {
      switch (data) {
        case "customer":
          return "客户";
        case "provider":
          return "供应商";
        case "executor":
          return "执行公司";
        case "reseller":
          return "经销商";
        case "enterprise":
          return "关联企业";
      }
    },
  }),
  mutations: {
    //修改模块中的状态   页面调用(this as any).$store.commit('config/setTest',3)
    // setTest(state, test) {
    //     state.test = test;
    // }
  },
  actions: {},
  getters: {},
};
export default configModule;
