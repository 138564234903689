import { createApp } from "vue";
import App from "./App.vue";
import Antd from 'ant-design-vue';
import router from "./router";
import store from "./store";
import "ant-design-vue/dist/antd.less";
import "@/common/css/font.scss";
import '@/assets/iam-iconfont/iconfont.css'
import { message, Modal } from 'ant-design-vue';
import PlusUi from 'plus-ui';
import 'plus-ui/lib/style.css';

const app: any = createApp(App);
app.config.globalProperties.$message = message;// (this as any).$message.success('111')
app.use(store).use(router).use(Antd).use(PlusUi).mount("#app");
export default app

